.footer-container {
  display: table;
  background-color: #f2f2f2;
  padding: 0;
  padding-top: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.footer {
  display: table-row;
}

.footer .footer-left {
  display: table-cell;
  height: 98px;
}

.footer .footer-left .vejl-btn {
  font-size: 12px;
}
.footer .footer-left .vejl-btn img {
  height: 12px;
  margin-right: 5px;
  margin-bottom: 4px;
}

.footer .footer-left .logo {
  position: absolute;
  bottom: 10px;
}

.footer .footer-left .logo img {
  height: 35px;
}

.footer .contact-info {
  font-size: 12px;
  text-align: right;
  display: table-cell;
}

.footer .contact-info h4 {
  margin: 0;
  margin-bottom: 5px;
}
.buggi-footer {
  background-color: #fff !important;
}

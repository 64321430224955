.filter-input {
  position: relative;
}

.filter-input--clear {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 9px;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  padding: 0px;
  text-align: center;
  line-height: 1;
  transition: background-color 200ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
input {
  &.form-control {
    //text-align: center;
  }
}

.material-input--form {
  margin-top: 15%;
}

.material-input--group {
  padding-left: 0;
}

.material-input--input {
  width: 100% !important;
}

.material-container--header {
  .logout-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      background: #f2f2f2;
      padding: 8px;
    }

    button {
      margin-left: 16px;
    }
  }
}

.material-container--header form {
  min-width: 150px;
}

.material-container--back-header-button {
  margin-top: 35px;
}

.material-data--container {
  margin-top: 20px;
  .spinner {
    border-left-color: black;
  }
}

.material-data--left {
  img {
    max-height: 200px;
    max-width: 100%;
  }
}

.material-data--right {
  vertical-align: top;
}

.material-data--counter {
  padding-top: 15px;
}

.material-data--submit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.material-data--submit button {
  margin-top: 10px;
}

.material-data--metadata {
  h3 {
    margin-top: 0;
  }

  div {
    margin-bottom: 5px;
  }
}

.material-data--itemname {
  font-weight: bold;
}

.material-reviewed--table {
  margin-top: 5rem;
  table {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}

.material-reviewed--table img {
  height: 32px;
}

.no-results {
  max-width: 50ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
}

.editor-indicator {
  margin-right: 20px;
  vertical-align: middle;
}

.select-material--container {
  margin-top: 40px;
}

.select-material--container h3 {
  margin: 50px 10px 20px 10px;
}

.edit-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #f5f5f5;
  min-height: 100px;
  margin-top: 30px;
  margin-left: 15px;
  padding: 15px 20px;
}
.guide-link {
  position: relative;
  bottom: -45px;
  left: -18px;
  text-decoration: underline;
}

.edit-box--header,
.edit-box--footer {
  flex-grow: 0;
}

.edit-box--footer {
  display: flex;
  justify-content: space-between;
}

.edit-box--header img {
  height: 36px;
}

.edit-box--content {
  flex-grow: 1;
  margin-top: 22px;
  margin-bottom: 39px;
}

.edit-box--header,
.edit-box--footer,
.edit-box--content {
}

.edit-box--columns {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
}

.edit-box--columns > * {
  width: 100%;
}

.edit-box .btn {
  border-radius: 18px;
  padding-left: 30px;
  padding-right: 30px;
}

.edit-box--buggi .btn {
  background-color: #2d9cdb;
  border-color: #dedede;
}

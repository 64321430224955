@use "../settings/colors";

.login--container {

  h2 {
    margin-top: 40px;
  }

  .container-fluid {
    height: 100%;
  }

  .input--container {
    margin: auto;
    width: 400px;
    max-width: 100%;
  }

  .input--form {
    width: 100%;
    .title {
      text-align: center;
      margin-top: 0;
      margin-bottom: 40px;
      font-weight: 500;
    }
    background-color: colors.$broken_white;
    padding: 40px;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
}



.login--description {
  max-width: 400px;
  margin: auto;
  margin-bottom: 2rem;
  ul, ol {
    padding-left: 14px;
  }
}

.input--description {
  margin-top: 40px;
}

.login--title {
  text-transform: uppercase;
  text-align: center;
  padding: 40px;
}

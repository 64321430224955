.navigation--sidebar {
  //background-color: #f5f5f5;
  //padding-top: 0.1rem;
  //padding-bottom: 0rem;
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  li ul {
    height: 0;
    overflow: hidden;
    padding-left: 2rem;
    transition: all 200ms;
  }
  ul.level-1 > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  ul.level-1 > li:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  li.is-openGroup > ul {
    display: block;
    height: auto;
    padding-bottom: 1rem;
  }
  .navigation--title {
    &:first-letter {
      text-transform: capitalize;
    }
    display: inline-block;
    width: 100%;
    position: relative;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    .glyphicon {
      font-size: 12px;
      color: #ccc;
      position: absolute;
      right: 0rem;
    }
  }

  .level-1 .navigation--title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 2rem;
    line-height: 1;
  }
  .level-2 .navigation--title {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 1.5rem;
  }
  .level-3 .navigation--title {
    font-size: 1.5rem;
  }

  li.has-currentPage > .navigation--title {
    font-weight: 500;
  }
}

.buggi-editor-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #1c2440, #6272b5);
    color: white;
    padding: 30px 0px;
    min-height: 100vh;
}
.header-title {
    margin-bottom: 50px;
    margin-top: 0px;
    font-size: 3rem;
}

.buggi-editor-book-info h1 {
    font-size: 2rem;
}
.buggi-editor-book-info h2 {
    font-size: 1.8rem;
    margin-top: 0;
}
.buggi-editor-book-info p {
    margin-top: 10px;
}
.buggi-editor-book-info p span {
    color: red;
}
.buggi-editor-book-info img {
    width: 250px;
}
.buggi-editor-book-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.range-slider-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
}
.range-slider-header p,
h2 {
    margin: 0px 3px;
}
.range-slider-header h2 {
    font-size: 2rem;
}
.range-slider-header img {
    margin-right: 5px;
}
.range-slider {
    margin: 12px 0px;
    max-width: 300px;
}
.selection-container {
    width: 100%;
    max-width: 300px;
}
.slider-item {
    margin: 35px 0px;
}

.range-slider-range {
    background: none;
    cursor: grab;
}
.range-slider-range:focus {
    outline: none;
}
.slider-labels {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .range-slider-range {
        -webkit-appearance: none;
    }

    .range-slider-range::-webkit-slider-thumb {
        cursor: grab;
        -webkit-appearance: none;
        border: 1px solid #000000;
        height: 26px;
        width: 16px;
        border-radius: 3px;
        background: #ffffff;
        cursor: pointer;
        margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
    }
    .range-slider-range::-webkit-slider-thumb:active {
        cursor: grabbing;
    }
}

.range-slider-range::-webkit-slider-runnable-track {
    background-color: #d3523b;

    width: 100%;
    height: 14px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}

/*FireFox*/
.range-slider-range::-moz-range-thumb {
    cursor: grab;
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 26px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

.range-slider-range::-moz-range-track {
    background-color: #d3523b;

    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border-radius: 1.3px;
}

/*ms*/
.range-slider-range::-ms-track {
    width: 100%;
    height: 14px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
}

.buggi-submit-btn {
    margin: 30px 0px;

    width: 100%;
    max-width: 300px;
    border: none;
    color: white;
    background: linear-gradient(to bottom, #16a3c5, #007aff);
    border-radius: 24px;
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    font-family: "Mukta-bold";
    outline:0;
    transition-duration: 0.2s;
}
.buggi-error-message{
    margin-top: -20px;
    margin-bottom: 20px;
    font-weight: bold;

}
.emoji-group-header {
    border-bottom: lightgrey 1px solid;

    margin-top: 100px;
}
.emoji-group-header h1 {
    font-size: 3.5rem;
}
.example-container-show {
}
.example-container-hide {
    display: none;
}
.show-examples-btn {
    cursor: pointer;
    font-weight: 700;
    background: none;
    border: none;
    padding-left: 0;
    margin-bottom: 10px;
    font-size: 1.5rem;
    display: flex;
    justify-self: start;
}
.show-examples-btn:focus {
    outline: none;
}
.example-item {
    display: flex;
    justify-content: space-between;
}
.example-value {
    margin-left: 5px;
}

@media only screen and (max-width: 576px) {
    .buggi-editor-book-info h1 {
        font-size: 1.7rem;
    }
    .buggi-editor-book-info h2 {
        font-size: 1.5rem;
        margin-top: 0;
    }
    .range-slider {
        margin: 20px 0px;
    }
    .range-slider-range::-webkit-slider-thumb {
        cursor: grab;
        -webkit-appearance: none;
        border: 1px solid #000000;
        height: 36px;
        width: 20px;
        border-radius: 3px;
        cursor: pointer;
        margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
        box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
    }
}

@media only screen and (min-width: 768px) {
    .selection-container {
        width: 100%;
        max-width: 600px;
    }
    .emojiSelector-sub-header {
        padding-top: 10px;
    }
}
.buggi-error-container {
    display: flex;
    justify-content: center;
    flex-direction: row !important;
}

//input overview
.input-overview-header {
    border-bottom: lightgrey 1px solid;
    width: 400px;
    max-width: 100%;
    margin-top: 50px;
    margin-bottom: 30px;
    padding-bottom: 10px;
}
.input-overview-header h2 {
    font-size: 3.2rem;
}

.emoji-info-top {
    line-height: 0px;
    margin-bottom: 4px;
    margin-top: 16px;
    display: flex;
    align-items: center;
}

.emoji-info-bar {
    width: 100%;
    height: 6px;
    border-radius: 4px;
}
.emoji-info-bar div {
    height: 6px;
    border-radius: 4px;
    transition: width 0.5s cubic-bezier(0.38, 1.95, 0.4, 0.4);
}
.input-overview-container {
    width: 300px;
    max-width: 100%;
}
.input-overview-emoji-name {
    margin-left: 8px;
    font-size: 2rem;
}
.emoji-info-container {
    margin: 30px 0;
}
.buggi-goback-btn {
    background: none;
    border: none;
    align-items: center;
    font-size: 1.6rem;
    display: flex;
}
.buggi-goback-btn img {
    width: 17px;
}
.buggi-editor-overview-top {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 20px;
    padding: 0px 30px;
}
.buggi-editor-overview-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, #1c2440, #6272b5);
    color: white;
    padding: 10px 0px;
    min-height: 100vh;
}

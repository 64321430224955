//modal
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.buggi-modal-main {
    position: fixed;
    background: white;
    width: 480px;
    max-width: 90%;
    height: 471px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 2%;
    display: flex;
    align-items: center;
    padding: 5px;
    flex-direction: column;
}
.buggi-modal-logo {
    width: 100px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.show-buggi-modal {
    display: block;
}

.hide-buggi-modal {
    display: none;
}
//modal end
.buggi-modal-text-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    align-items: center;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.56);
    text-align: center;
}
.modal-emoji {
    width: 170px !important;
    height: 170px !important;
}
.modal-emoji-small {
    width: 24px !important;
    height: 24px !important;
}
.buggi-modal-subtitle {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}
.buggi-modal-subtitle p {
    margin: 0;
    margin-right: 3px;
}

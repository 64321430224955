.accounting-app {
  i {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .accounting-arrow-desc {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .accounting-arrow-asc {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
}

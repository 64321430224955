.thankyou {
  position: relative;
  padding:50px;
  margin-top: 100px;
  max-width: 400px;
  margin-bottom: 50px;
  background-color:#f5f5f5;
  border:solid 1px lightgrey;

}

.thankyou--p {
  margin: 4rem;
}

.thankyou--team {
  display: inline-block;
  padding: 1rem;
  border-radius: 4px;
}
.meta--container {
  padding: 0;
}

.meta--slider {
  display: flex;
  position: relative;
  transition: left 500ms ease-in-out;
  height: calc(100vh - 100px);
}

.meta--page-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.meta--page {
  padding: 1rem;
  width: 100%;
  margin: 0;
  flex-shrink: 0;
}

.meta--innerlink {
  cursor: pointer;
}

.meta--subnav {
  display: inline-flex;
  width: 100%;
}

.meta--page-right-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: auto;

  &.no-right-margin {
    margin-right: 0;
  }
}

.meta--page-left-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;

  &.no-left-margin {
    margin-left: 0;
  }
}

.meta--page-row {
  flex-grow: 0;
  flex-wrap: wrap;
}

.meta--header-fixed {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 2;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;

  .container {
    position: relative;
  }
}

.meta--header {
  height: 100px;
}

.meta--nav {
  margin: 0;
  position: absolute;
  top: 2.5rem;
  cursor: pointer;
}

.meta--element label span {
  cursor: pointer;
}

input.meta--checkbox {
  margin-right: 1rem;
  cursor: pointer;
}

.meta--next {
  right: 0;
}

.meta--prev {
  left: 0;
}

/*=== NOTES ===*/

.note--wrap {
  display: inline-block;
  margin-left: 1rem;
  position: relative;

  span {
    display: inline-block;
    width: 17.5px;
    height: 17.5px;
    line-height: 17.5px;
    font-weight: bold;
    font-size: 12px;
    vertical-align: middle;
    text-align: center;
    color: white;
    background-color: #337ab7;
    border-radius: 50%;
    cursor: pointer;
  }
}

.note--wrap--special {
  margin-left: 1rem;
  display: inline-block;
  width: 17.5px;
  height: 17.5px;
  line-height: 17.5px;
  font-weight: bold;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  color: white;
  background-color: #337ab7;
  border-radius: 50%;
  cursor: pointer;
}

.note--box {
  width: 300px;
  position: absolute;
  top: calc(100% + 1rem);
  left: -1rem;
  padding: 1rem;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);

  display: none;

  .note--box--arrow {
    position: absolute;
    top: -5px;
    left: calc(1rem + (17.5px / 2) - 5px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid white;
  }

  span {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    line-height: 18px;
    background-color: lightgrey;
    color: #333;

    &:hover {
      background-color: #333;
      color: lightgrey;
    }
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 0 0;
  }

  p {
    margin: 0.5rem 0 0 0;
  }

  ul {
    padding-left: 16px;
  }
}

.note--wrap.isOpen .note--box {
  display: block;
}

.note--dimmer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  display: none;
}

.note--wrap.isOpen .note--dimmer {
  display: block;
}

@media only screen and (max-width: 576px) {
  .note--box {
    width: calc(100% - 2rem);
    position: fixed;
    top: auto;
    bottom: 1rem;
    left: 1rem;

    display: block;
    transform: translateY(100vw);
    transition-duration: 0.3s;

    .note--box--arrow {
      display: none;
    }
  }

  .note--wrap.isOpen .note--box {
    transform: translate(0);
  }
}

/*=== COMMENTS ===*/

.comment--container {
  background-color: #f5f5f5;
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  margin-top: 20px;
}

.comment--textarea {
  width: 100%;
}

.comment--savebtn {
  width: 25%;
  float: right;
  margin-top: 5px;
}

/*=== SUMMARY ===*/

.meta--page--summary--container {
  background-color: #f5f5f5;
  width: 100%;
  padding: 2rem;

  hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border-top: 1px solid #ccc;
  }
}

.summary--page {
  h4 {
    small {
      color: #656565;
    }

    span {
      color: #1a5a8a;
      cursor: pointer;
    }
  }
}

.meta--page--summary--buttons {
  display: flex;
  justify-content: space-between;
}

.summary--subnavigation {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.capTitle::first-letter {
  text-transform: uppercase;
}

.summary-page--suggestion {
  color: #367ee8;
}

/*=== SUMMARY SEQUENCE ===*/
.priority {
  display: inline-flex;
  margin: 12px;
}

.summary-selected {
  font-weight: bold;
}

.summary-selected.obsolete {
  text-decoration: line-through;
}

.refresh {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 20px;
}

.refreshBtn {
  font-size: 10px;
  background: #d8d8d8;
}

.gi-2x {
  font-size: 14px;
}

.summary--title {
  font-weight: bold;
}

/*=== ADD SENTENCES ===*/

.addSentence--obsolete-sentence {
  color: red;
  margin-top: -9px;
  margin-left: 10px;
  font-weight: normal;
}

@media only screen and (max-width: 767px) {
  .addSentence--obsolete-sentence {
    right: initial;
    margin-top: 21px;
  }
  td.summary-sentences.obsolete {
    height: 78px;
  }
}

.add-sentences-container {
}

.selected-senteces {
  margin-bottom: 40px;
}

.sentence-input-item {
  margin-bottom: 30px;
}

.sentence-input-item input {
  width: 100%;
  padding-left: 10px;
  background: #ffffff;
  border-radius: 0px;
  border: 1px solid #666666;
  height: 48px;

  color: rgb(51, 51, 51);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.tag-container {
  color: #666666;
  display: flex;
  align-items: center;
  margin-top: 10px;

  font-size: 14px;
  min-height: 35px;
}

.tag-container label {
  padding: 5px 10px;
  background: #e9eaeb;
  border-radius: 4px;
  margin-left: 5px;
}

.label-container {
  display: flex;
  justify-content: space-between;
}

.sentence-selector label {
  background: #d8d8d8;
  border-radius: 4px;
  padding: 3px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.sentence-selector label.selected {
  background-color: #2a56dc;
  color: white;
}

.sentence-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
}

.sentence-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(151, 151, 151);
  margin-bottom: 10px;
}

.sentence-list-header label {
  font-weight: bold;
}

/*=== UTILS ===*/

.width-fit-content {
  width: fit-content;
}

.float-right {
  float: right;
}

@media only screen and (max-width: 768px) {
  .meta-edit-button {
    float: none;
    width: 100%;
    margin-top: 2rem;
  }
}

.hide-element {
  display: none !important;
}

/**BOOK PREVIEW*/

/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css */


.mr-2 {
  margin-right: 0.5rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  img {
    page-break-inside: avoid;
  }
  h2,
  p {
    orphans: 3;
    widows: 3;
  }
  h2 {
    page-break-after: avoid;
  }

}

/* local font imports */
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../../public/fonts/montserrat/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('../../../public/fonts/montserrat/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
  url('../../../public/fonts/montserrat/montserrat-v14-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
  url('../../../public/fonts/montserrat/montserrat-v14-latin-regular.woff') format('woff'),
    /* Modern Browsers */
  url('../../../public/fonts/montserrat/montserrat-v14-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
  url('../../../public/fonts/montserrat/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

.material-icons {
  font-family: Material Icons;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.material-icons.md-large {
  font-size: var(--4pt);
}

.button {
  padding: 9px 20px;
  border-radius: 4px;
  color: #00414b;
  background-color: #f3f3f3;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  cursor: pointer;
}

.button__quinary {
  background-color: #e9eaeb;
}

@media (hover: hover) {
  .button__quinary:hover {
    background-color: #bdd5fd;
  }
}

.button__medium {
  padding: 7px 10px;
  font-size: 1rem;
}

.Text {
  font-family: 'Montserrat', sans-serif;
  color: #00414b;
  font-weight: 400;
  line-height: 1.42rem;
  margin: 0;
}

.Text__body--color-petroleum--weight-semibold--transform-uppercase {
  font-weight: 600;
  text-transform: uppercase;
  color: #00414b;
}

.Title {
  font-family: 'Montserrat', sans-serif;
  color: #00414b;
}

.Title__title6 {
  font-weight: 600;
  line-height: 1.57rem;
}

.Title__title6 {
  font-size: 1rem;
}

.Title__title6--weight-normal {
  font-weight: 400;
}

.book-cover {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 3px;
}

.book-cover img {
  width: 100%;
  max-height: 100%;
  height: auto;
}


.swiper-slide {
  height: 100%;
}

.swiper-slide::-webkit-scrollbar-track {
  background-color: #e9eaeb;
}

.swiper-slide::-webkit-scrollbar {
  width: 6px;
}

.swiper-slide::-webkit-scrollbar-thumb {
  background-color: #00414b;
}

.swiper-wrapper .work-card {
  margin: 0 var(--3pt) 0 0;
}

.diblio-logo {
  width: 17px;
  height: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.diblio-logo div {
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.diblio-logo div:first-child {
  width: 70%;
}

.diblio-logo div:last-child {
  width: 80%;
}

.RemindsOf {
  width: 143px;
  height: 33px;
  display: inline-flex;
  align-items: center;
  background-color: #74a9ff;
  border-radius: 4px;
  cursor: pointer;
}

.RemindsOf p {
  padding-top: 1px;
}

.RemindsOf .diblio-logo div {
  background-color: #00414b;
}

.work-card {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 50px;

  width: 215px;
  min-width: 215px;
  vertical-align: top;
  text-align: left;
  cursor: pointer;
}

.work-card label {
  font-weight: 500;
}

.work-card .work-card__content {
  height: 100%;
}

.work-card .book-cover {
  width: 100%;
  height: 70%;
  padding: 20px;
  background-color: #f8f8f8;
  object-fit: contain;
}

.work-card .book-cover:focus {
  outline: 2px solid;
}

.work-card .book-cover img {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.work-card .work-card__title {
  margin: 0;
}

.work-card .work-card__creator {
  margin-bottom: var(--1pt);
}

.work-card .hover-details {
  text-align: left;
  opacity: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.work-card:focus .hover-details,
.work-card:hover .hover-details {
  opacity: 1;
}

.hover-details-fade {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  from(hsla(0, 0%, 100%, 0)),
                  color-stop(60%, #fff),
                  color-stop(30%, #fff)
  );
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff 60%, #fff 0);
  z-index: 1;
}

.work-card:hover .hover-details-fade {
  opacity: 1;
}

.work-card .expand-more-wrapper {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: -180px;
  left: 0;
}

.work-card .work-card__tax-description {
  display: block;
  margin-top: var(--1pt) !important;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .work-card {
    height: 350px;
    width: 160px;
    min-width: 160px;
  }
}

@media only screen and (max-width: 576px) {
  .work-card {
    height: 180px;
    width: 130px;
    min-width: 130px;
  }
  .work-card .book-cover {
    height: 100%;
    padding: 10px;
  }
  .hover-details,
  .hover-details-fade,
  .work-card .work-card__tax-description {
    display: none;
  }
}

button:disabled {
  color: #a7a7a7;
  background-color: #e9eaeb;
}


.diblio-logo {
  width: 17px;
  height: 13px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.diblio-logo div {
  width: 100%;
  height: 3px;
  background-color: white;
}

.diblio-logo div:first-child {
  width: 70%;
}

.diblio-logo div:last-child {
  width: 80%;
}

.bookmark-icon {
  width: 22.4px !important;
}

.sentences-intro-text {
  margin-bottom: 20px;
}

.no-sentences-message {
  color: red;
  margin-top: 50px;

}

.no-sentences-table {
  margin-bottom: 70px;
}

.no-sentences-table .sentence-list-header {
  margin-bottom: 20px;
}

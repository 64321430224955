
.Emoji_small {
  width: 37.5px;
  height: 37.5px;
}
.Emoji_large {
  width: 50px;
}

@media only screen and (min-width: 320px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (min-width: 576px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 62.5px;
    height: 62.5px;
  }
}

@media only screen and (min-width: 768px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 1200px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 75px;
    height: 75px;
  }
}

/*=== SPINNER ===*/

.spinner {
  display: inline-block;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: .8em;
  height: .8em;
}
.spinner {
  margin-left: 5px;
  vertical-align: text-top;
  font-size: 18px;
  position: relative;
  text-indent: -9999em;
  border: .1em solid rgba(255, 255, 255, 0.2);
  border-left-color: #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.fade-appear {
  height: 0;
}

.fade-appear.fade-appear-active {
  height: 100%;
  transition: height 500ms ease-out;
}



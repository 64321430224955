/*
 * Row with equal height columns
 * --------------------------------------------------
 */
.row-eq-height {
  display: flex;
}

.title--thin {
  font-weight: 100;
}

/* Variables */
:root {
  --spacing-none: 0;
  --spacing-extra-small: .25rem;
  --spacing-small: .5rem;
  --spacing-medium: 1rem;
  --spacing-large: 2rem;
  --spacing-extra-large: 4rem;
  --spacing-extra-extra-large: 8rem;
  --spacing-extra-extra-extra-large: 16rem;
}

/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/

   An eight step powers of two scale ranging from 0 to 16rem.

   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left

     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/
.pa0 {
  padding: var(--spacing-none);
}

.pa1 {
  padding: var(--spacing-extra-small);
}

.pa2 {
  padding: var(--spacing-small);
}

.pa3 {
  padding: var(--spacing-medium);
}

.pa4 {
  padding: var(--spacing-large);
}

.pa5 {
  padding: var(--spacing-extra-large);
}

.pa6 {
  padding: var(--spacing-extra-extra-large);
}

.pa7 {
  padding: var(--spacing-extra-extra-extra-large);
}

.pl0 {
  padding-left: var(--spacing-none);
}

.pl1 {
  padding-left: var(--spacing-extra-small);
}

.pl2 {
  padding-left: var(--spacing-small);
}

.pl3 {
  padding-left: var(--spacing-medium);
}

.pl4 {
  padding-left: var(--spacing-large);
}

.pl5 {
  padding-left: var(--spacing-extra-large);
}

.pl6 {
  padding-left: var(--spacing-extra-extra-large);
}

.pl7 {
  padding-left: var(--spacing-extra-extra-extra-large);
}

.pr0 {
  padding-right: var(--spacing-none);
}

.pr1 {
  padding-right: var(--spacing-extra-small);
}

.pr2 {
  padding-right: var(--spacing-small);
}

.pr3 {
  padding-right: var(--spacing-medium);
}

.pr4 {
  padding-right: var(--spacing-large);
}

.pr5 {
  padding-right: var(--spacing-extra-large);
}

.pr6 {
  padding-right: var(--spacing-extra-extra-large);
}

.pr7 {
  padding-right: var(--spacing-extra-extra-extra-large);
}

.pb0 {
  padding-bottom: var(--spacing-none);
}

.pb1 {
  padding-bottom: var(--spacing-extra-small);
}

.pb2 {
  padding-bottom: var(--spacing-small);
}

.pb3 {
  padding-bottom: var(--spacing-medium);
}

.pb4 {
  padding-bottom: var(--spacing-large);
}

.pb5 {
  padding-bottom: var(--spacing-extra-large);
}

.pb6 {
  padding-bottom: var(--spacing-extra-extra-large);
}

.pb7 {
  padding-bottom: var(--spacing-extra-extra-extra-large);
}

.pt0 {
  padding-top: var(--spacing-none);
}

.pt1 {
  padding-top: var(--spacing-extra-small);
}

.pt2 {
  padding-top: var(--spacing-small);
}

.pt3 {
  padding-top: var(--spacing-medium);
}

.pt4 {
  padding-top: var(--spacing-large);
}

.pt5 {
  padding-top: var(--spacing-extra-large);
}

.pt6 {
  padding-top: var(--spacing-extra-extra-large);
}

.pt7 {
  padding-top: var(--spacing-extra-extra-extra-large);
}

.pv0 {
  padding-top: var(--spacing-none);
  padding-bottom: var(--spacing-none);
}

.pv1 {
  padding-top: var(--spacing-extra-small);
  padding-bottom: var(--spacing-extra-small);
}

.pv2 {
  padding-top: var(--spacing-small);
  padding-bottom: var(--spacing-small);
}

.pv3 {
  padding-top: var(--spacing-medium);
  padding-bottom: var(--spacing-medium);
}

.pv4 {
  padding-top: var(--spacing-large);
  padding-bottom: var(--spacing-large);
}

.pv5 {
  padding-top: var(--spacing-extra-large);
  padding-bottom: var(--spacing-extra-large);
}

.pv6 {
  padding-top: var(--spacing-extra-extra-large);
  padding-bottom: var(--spacing-extra-extra-large);
}

.pv7 {
  padding-top: var(--spacing-extra-extra-extra-large);
  padding-bottom: var(--spacing-extra-extra-extra-large);
}

.ph0 {
  padding-left: var(--spacing-none);
  padding-right: var(--spacing-none);
}

.ph1 {
  padding-left: var(--spacing-extra-small);
  padding-right: var(--spacing-extra-small);
}

.ph2 {
  padding-left: var(--spacing-small);
  padding-right: var(--spacing-small);
}

.ph3 {
  padding-left: var(--spacing-medium);
  padding-right: var(--spacing-medium);
}

.ph4 {
  padding-left: var(--spacing-large);
  padding-right: var(--spacing-large);
}

.ph5 {
  padding-left: var(--spacing-extra-large);
  padding-right: var(--spacing-extra-large);
}

.ph6 {
  padding-left: var(--spacing-extra-extra-large);
  padding-right: var(--spacing-extra-extra-large);
}

.ph7 {
  padding-left: var(--spacing-extra-extra-extra-large);
  padding-right: var(--spacing-extra-extra-extra-large);
}

.ma0 {
  margin: var(--spacing-none);
}

.ma1 {
  margin: var(--spacing-extra-small);
}

.ma2 {
  margin: var(--spacing-small);
}

.ma3 {
  margin: var(--spacing-medium);
}

.ma4 {
  margin: var(--spacing-large);
}

.ma5 {
  margin: var(--spacing-extra-large);
}

.ma6 {
  margin: var(--spacing-extra-extra-large);
}

.ma7 {
  margin: var(--spacing-extra-extra-extra-large);
}

.ml0 {
  margin-left: var(--spacing-none);
}

.ml1 {
  margin-left: var(--spacing-extra-small);
}

.ml2 {
  margin-left: var(--spacing-small);
}

.ml3 {
  margin-left: var(--spacing-medium);
}

.ml4 {
  margin-left: var(--spacing-large);
}

.ml5 {
  margin-left: var(--spacing-extra-large);
}

.ml6 {
  margin-left: var(--spacing-extra-extra-large);
}

.ml7 {
  margin-left: var(--spacing-extra-extra-extra-large);
}

.mr0 {
  margin-right: var(--spacing-none);
}

.mr1 {
  margin-right: var(--spacing-extra-small);
}

.mr2 {
  margin-right: var(--spacing-small);
}

.mr3 {
  margin-right: var(--spacing-medium);
}

.mr4 {
  margin-right: var(--spacing-large);
}

.mr5 {
  margin-right: var(--spacing-extra-large);
}

.mr6 {
  margin-right: var(--spacing-extra-extra-large);
}

.mr7 {
  margin-right: var(--spacing-extra-extra-extra-large);
}

.mb0 {
  margin-bottom: var(--spacing-none);
}

.mb1 {
  margin-bottom: var(--spacing-extra-small);
}

.mb2 {
  margin-bottom: var(--spacing-small);
}

.mb3 {
  margin-bottom: var(--spacing-medium);
}

.mb4 {
  margin-bottom: var(--spacing-large);
}

.mb5 {
  margin-bottom: var(--spacing-extra-large);
}

.mb6 {
  margin-bottom: var(--spacing-extra-extra-large);
}

.mb7 {
  margin-bottom: var(--spacing-extra-extra-extra-large);
}

.mt0 {
  margin-top: var(--spacing-none);
}

.mt1 {
  margin-top: var(--spacing-extra-small);
}

.mt2 {
  margin-top: var(--spacing-small);
}

.mt3 {
  margin-top: var(--spacing-medium);
}

.mt4 {
  margin-top: var(--spacing-large);
}

.mt5 {
  margin-top: var(--spacing-extra-large);
}

.mt6 {
  margin-top: var(--spacing-extra-extra-large);
}

.mt7 {
  margin-top: var(--spacing-extra-extra-extra-large);
}

.mv0 {
  margin-top: var(--spacing-none);
  margin-bottom: var(--spacing-none);
}

.mv1 {
  margin-top: var(--spacing-extra-small);
  margin-bottom: var(--spacing-extra-small);
}

.mv2 {
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-small);
}

.mv3 {
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
}

.mv4 {
  margin-top: var(--spacing-large);
  margin-bottom: var(--spacing-large);
}

.mv5 {
  margin-top: var(--spacing-extra-large);
  margin-bottom: var(--spacing-extra-large);
}

.mv6 {
  margin-top: var(--spacing-extra-extra-large);
  margin-bottom: var(--spacing-extra-extra-large);
}

.mv7 {
  margin-top: var(--spacing-extra-extra-extra-large);
  margin-bottom: var(--spacing-extra-extra-extra-large);
}

.mh0 {
  margin-left: var(--spacing-none);
  margin-right: var(--spacing-none);
}

.mh1 {
  margin-left: var(--spacing-extra-small);
  margin-right: var(--spacing-extra-small);
}

.mh2 {
  margin-left: var(--spacing-small);
  margin-right: var(--spacing-small);
}

.mh3 {
  margin-left: var(--spacing-medium);
  margin-right: var(--spacing-medium);
}

.mh4 {
  margin-left: var(--spacing-large);
  margin-right: var(--spacing-large);
}

.mh5 {
  margin-left: var(--spacing-extra-large);
  margin-right: var(--spacing-extra-large);
}

.mh6 {
  margin-left: var(--spacing-extra-extra-large);
  margin-right: var(--spacing-extra-extra-large);
}

.mh7 {
  margin-left: var(--spacing-extra-extra-extra-large);
  margin-right: var(--spacing-extra-extra-extra-large);
}

.capitalized {
  display: inline-block;
}
.capitalized::first-letter {
  text-transform: capitalize;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.buggi-editor-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #1c2440, #6272b5);
  color: white;
  padding: 30px 0px;
  min-height: 100vh;
}

.header-title {
  margin-bottom: 50px;
  margin-top: 0px;
  font-size: 3rem;
}

.buggi-editor-book-info h1 {
  font-size: 2rem;
}

.buggi-editor-book-info h2 {
  font-size: 1.8rem;
  margin-top: 0;
}

.buggi-editor-book-info p {
  margin-top: 10px;
}

.buggi-editor-book-info p span {
  color: red;
}

.buggi-editor-book-info img {
  width: 250px;
}

.buggi-editor-book-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.range-slider-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.range-slider-header p,
h2 {
  margin: 0px 3px;
}

.range-slider-header h2 {
  font-size: 2rem;
}

.range-slider-header img {
  margin-right: 5px;
}

.range-slider {
  margin: 12px 0px;
  max-width: 300px;
}

.selection-container {
  width: 100%;
  max-width: 300px;
}

.slider-item {
  margin: 35px 0px;
}

.range-slider-range {
  background: none;
  cursor: grab;
}

.range-slider-range:focus {
  outline: none;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  max-width: 300px;
}

/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .range-slider-range {
    -webkit-appearance: none;
  }
  .range-slider-range::-webkit-slider-thumb {
    cursor: grab;
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 26px;
    width: 16px;
    border-radius: 3px;
    background: #ffffff;
    cursor: pointer;
    margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
  }
  .range-slider-range::-webkit-slider-thumb:active {
    cursor: grabbing;
  }
}
.range-slider-range::-webkit-slider-runnable-track {
  background-color: #d3523b;
  width: 100%;
  height: 14px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

/*FireFox*/
.range-slider-range::-moz-range-thumb {
  cursor: grab;
  -webkit-appearance: none;
  border: 1px solid #000000;
  height: 26px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

.range-slider-range::-moz-range-track {
  background-color: #d3523b;
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border-radius: 1.3px;
}

/*ms*/
.range-slider-range::-ms-track {
  width: 100%;
  height: 14px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

.buggi-submit-btn {
  margin: 30px 0px;
  width: 100%;
  max-width: 300px;
  border: none;
  color: white;
  background: linear-gradient(to bottom, #16a3c5, #007aff);
  border-radius: 24px;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 24px;
  font-family: "Mukta-bold";
  outline: 0;
  transition-duration: 0.2s;
}

.buggi-error-message {
  margin-top: -20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.emoji-group-header {
  border-bottom: lightgrey 1px solid;
  margin-top: 100px;
}

.emoji-group-header h1 {
  font-size: 3.5rem;
}

.example-container-hide {
  display: none;
}

.show-examples-btn {
  cursor: pointer;
  font-weight: 700;
  background: none;
  border: none;
  padding-left: 0;
  margin-bottom: 10px;
  font-size: 1.5rem;
  display: flex;
  justify-self: start;
}

.show-examples-btn:focus {
  outline: none;
}

.example-item {
  display: flex;
  justify-content: space-between;
}

.example-value {
  margin-left: 5px;
}

@media only screen and (max-width: 576px) {
  .buggi-editor-book-info h1 {
    font-size: 1.7rem;
  }
  .buggi-editor-book-info h2 {
    font-size: 1.5rem;
    margin-top: 0;
  }
  .range-slider {
    margin: 20px 0px;
  }
  .range-slider-range::-webkit-slider-thumb {
    cursor: grab;
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 36px;
    width: 20px;
    border-radius: 3px;
    cursor: pointer;
    margin-top: -10px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
  }
}
@media only screen and (min-width: 768px) {
  .selection-container {
    width: 100%;
    max-width: 600px;
  }
  .emojiSelector-sub-header {
    padding-top: 10px;
  }
}
.buggi-error-container {
  display: flex;
  justify-content: center;
  flex-direction: row !important;
}

.input-overview-header {
  border-bottom: lightgrey 1px solid;
  width: 400px;
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.input-overview-header h2 {
  font-size: 3.2rem;
}

.emoji-info-top {
  line-height: 0px;
  margin-bottom: 4px;
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.emoji-info-bar {
  width: 100%;
  height: 6px;
  border-radius: 4px;
}

.emoji-info-bar div {
  height: 6px;
  border-radius: 4px;
  transition: width 0.5s cubic-bezier(0.38, 1.95, 0.4, 0.4);
}

.input-overview-container {
  width: 300px;
  max-width: 100%;
}

.input-overview-emoji-name {
  margin-left: 8px;
  font-size: 2rem;
}

.emoji-info-container {
  margin: 30px 0;
}

.buggi-goback-btn {
  background: none;
  border: none;
  align-items: center;
  font-size: 1.6rem;
  display: flex;
}

.buggi-goback-btn img {
  width: 17px;
}

.buggi-editor-overview-top {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 20px;
  padding: 0px 30px;
}

.buggi-editor-overview-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, #1c2440, #6272b5);
  color: white;
  padding: 10px 0px;
  min-height: 100vh;
}

.Emoji_small {
  width: 37.5px;
  height: 37.5px;
}

.Emoji_large {
  width: 50px;
}

@media only screen and (min-width: 320px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (min-width: 576px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 62.5px;
    height: 62.5px;
  }
}
@media only screen and (min-width: 768px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 75px;
    height: 75px;
  }
}
@media only screen and (min-width: 1200px) {
  .Emoji_small {
    width: 50px;
    height: 50px;
  }
  .Emoji_large {
    width: 75px;
    height: 75px;
  }
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.buggi-modal-main {
  position: fixed;
  background: white;
  width: 480px;
  max-width: 90%;
  height: 471px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2%;
  display: flex;
  align-items: center;
  padding: 5px;
  flex-direction: column;
}

.buggi-modal-logo {
  width: 100px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.show-buggi-modal {
  display: block;
}

.hide-buggi-modal {
  display: none;
}

.buggi-modal-text-container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-items: center;
  font-weight: 800;
  color: rgba(0, 0, 0, 0.56);
  text-align: center;
}

.modal-emoji {
  width: 170px !important;
  height: 170px !important;
}

.modal-emoji-small {
  width: 24px !important;
  height: 24px !important;
}

.buggi-modal-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.buggi-modal-subtitle p {
  margin: 0;
  margin-right: 3px;
}

.filter-input {
  position: relative;
}

.filter-input--clear {
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 9px;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
  padding: 0px;
  text-align: center;
  line-height: 1;
  transition: background-color 200ms;
}
.filter-input--clear:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.login--container h2 {
  margin-top: 40px;
}
.login--container .container-fluid {
  height: 100%;
}
.login--container .input--container {
  margin: auto;
  width: 400px;
  max-width: 100%;
}
.login--container .input--form {
  width: 100%;
  background-color: #f2f2f2;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.login--container .input--form .title {
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  font-weight: 500;
}

.login--description {
  max-width: 400px;
  margin: auto;
  margin-bottom: 2rem;
}
.login--description ul, .login--description ol {
  padding-left: 14px;
}

.input--description {
  margin-top: 40px;
}

.login--title {
  text-transform: uppercase;
  text-align: center;
  padding: 40px;
}

.material-input--form {
  margin-top: 15%;
}

.material-input--group {
  padding-left: 0;
}

.material-input--input {
  width: 100% !important;
}

.material-container--header .logout-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.material-container--header .logout-header span {
  background: #f2f2f2;
  padding: 8px;
}
.material-container--header .logout-header button {
  margin-left: 16px;
}

.material-container--header form {
  min-width: 150px;
}

.material-container--back-header-button {
  margin-top: 35px;
}

.material-data--container {
  margin-top: 20px;
}
.material-data--container .spinner {
  border-left-color: black;
}

.material-data--left img {
  max-height: 200px;
  max-width: 100%;
}

.material-data--right {
  vertical-align: top;
}

.material-data--counter {
  padding-top: 15px;
}

.material-data--submit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.material-data--submit button {
  margin-top: 10px;
}

.material-data--metadata h3 {
  margin-top: 0;
}
.material-data--metadata div {
  margin-bottom: 5px;
}

.material-data--itemname {
  font-weight: bold;
}

.material-reviewed--table {
  margin-top: 5rem;
}
.material-reviewed--table table tbody tr {
  cursor: pointer;
}

.material-reviewed--table img {
  height: 32px;
}

.no-results {
  max-width: 50ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
}

.editor-indicator {
  margin-right: 20px;
  vertical-align: middle;
}

.select-material--container {
  margin-top: 40px;
}

.select-material--container h3 {
  margin: 50px 10px 20px 10px;
}

.edit-box {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: #f5f5f5;
  min-height: 100px;
  margin-top: 30px;
  margin-left: 15px;
  padding: 15px 20px;
}

.guide-link {
  position: relative;
  bottom: -45px;
  left: -18px;
  text-decoration: underline;
}

.edit-box--header,
.edit-box--footer {
  flex-grow: 0;
}

.edit-box--footer {
  display: flex;
  justify-content: space-between;
}

.edit-box--header img {
  height: 36px;
}

.edit-box--content {
  flex-grow: 1;
  margin-top: 22px;
  margin-bottom: 39px;
}

.edit-box--columns {
  display: flex;
  justify-content: space-between;
  margin-left: -15px;
}

.edit-box--columns > * {
  width: 100%;
}

.edit-box .btn {
  border-radius: 18px;
  padding-left: 30px;
  padding-right: 30px;
}

.edit-box--buggi .btn {
  background-color: #2d9cdb;
  border-color: #dedede;
}

.meta--container {
  padding: 0;
}

.meta--slider {
  display: flex;
  position: relative;
  transition: left 500ms ease-in-out;
  height: calc(100vh - 100px);
}

.meta--page-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.meta--page {
  padding: 1rem;
  width: 100%;
  margin: 0;
  flex-shrink: 0;
}

.meta--innerlink {
  cursor: pointer;
}

.meta--subnav {
  display: inline-flex;
  width: 100%;
}

.meta--page-right-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: auto;
}
.meta--page-right-btn.no-right-margin {
  margin-right: 0;
}

.meta--page-left-btn {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
}
.meta--page-left-btn.no-left-margin {
  margin-left: 0;
}

.meta--page-row {
  flex-grow: 0;
  flex-wrap: wrap;
}

.meta--header-fixed {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 2;
  border-bottom: 1px solid #f2f2f2;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}
.meta--header-fixed .container {
  position: relative;
}

.meta--header {
  height: 100px;
}

.meta--nav {
  margin: 0;
  position: absolute;
  top: 2.5rem;
  cursor: pointer;
}

.meta--element label span {
  cursor: pointer;
}

input.meta--checkbox {
  margin-right: 1rem;
  cursor: pointer;
}

.meta--next {
  right: 0;
}

.meta--prev {
  left: 0;
}

/*=== NOTES ===*/
.note--wrap {
  display: inline-block;
  margin-left: 1rem;
  position: relative;
}
.note--wrap span {
  display: inline-block;
  width: 17.5px;
  height: 17.5px;
  line-height: 17.5px;
  font-weight: bold;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  color: white;
  background-color: #337ab7;
  border-radius: 50%;
  cursor: pointer;
}

.note--wrap--special {
  margin-left: 1rem;
  display: inline-block;
  width: 17.5px;
  height: 17.5px;
  line-height: 17.5px;
  font-weight: bold;
  font-size: 12px;
  vertical-align: middle;
  text-align: center;
  color: white;
  background-color: #337ab7;
  border-radius: 50%;
  cursor: pointer;
}

.note--box {
  width: 300px;
  position: absolute;
  top: calc(100% + 1rem);
  left: -1rem;
  padding: 1rem;
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  display: none;
}
.note--box .note--box--arrow {
  position: absolute;
  top: -5px;
  left: calc(1rem + 8.75px - 5px);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid white;
}
.note--box span {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  line-height: 18px;
  background-color: lightgrey;
  color: #333;
}
.note--box span:hover {
  background-color: #333;
  color: lightgrey;
}
.note--box h2 {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 0 0;
}
.note--box p {
  margin: 0.5rem 0 0 0;
}
.note--box ul {
  padding-left: 16px;
}

.note--wrap.isOpen .note--box {
  display: block;
}

.note--dimmer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: none;
}

.note--wrap.isOpen .note--dimmer {
  display: block;
}

@media only screen and (max-width: 576px) {
  .note--box {
    width: calc(100% - 2rem);
    position: fixed;
    top: auto;
    bottom: 1rem;
    left: 1rem;
    display: block;
    transform: translateY(100vw);
    transition-duration: 0.3s;
  }
  .note--box .note--box--arrow {
    display: none;
  }
  .note--wrap.isOpen .note--box {
    transform: translate(0);
  }
}
/*=== COMMENTS ===*/
.comment--container {
  background-color: #f5f5f5;
  width: 100%;
  padding: 0 2rem 2rem 2rem;
  margin-top: 20px;
}

.comment--textarea {
  width: 100%;
}

.comment--savebtn {
  width: 25%;
  float: right;
  margin-top: 5px;
}

/*=== SUMMARY ===*/
.meta--page--summary--container {
  background-color: #f5f5f5;
  width: 100%;
  padding: 2rem;
}
.meta--page--summary--container hr {
  margin-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid #ccc;
}

.summary--page h4 small {
  color: #656565;
}
.summary--page h4 span {
  color: #1a5a8a;
  cursor: pointer;
}

.meta--page--summary--buttons {
  display: flex;
  justify-content: space-between;
}

.summary--subnavigation {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.capTitle::first-letter {
  text-transform: uppercase;
}

.summary-page--suggestion {
  color: #367ee8;
}

/*=== SUMMARY SEQUENCE ===*/
.priority {
  display: inline-flex;
  margin: 12px;
}

.summary-selected {
  font-weight: bold;
}

.summary-selected.obsolete {
  text-decoration: line-through;
}

.refresh {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 20px;
}

.refreshBtn {
  font-size: 10px;
  background: #d8d8d8;
}

.gi-2x {
  font-size: 14px;
}

.summary--title {
  font-weight: bold;
}

/*=== ADD SENTENCES ===*/
.addSentence--obsolete-sentence {
  color: red;
  margin-top: -9px;
  margin-left: 10px;
  font-weight: normal;
}

@media only screen and (max-width: 767px) {
  .addSentence--obsolete-sentence {
    right: initial;
    margin-top: 21px;
  }
  td.summary-sentences.obsolete {
    height: 78px;
  }
}
.selected-senteces {
  margin-bottom: 40px;
}

.sentence-input-item {
  margin-bottom: 30px;
}

.sentence-input-item input {
  width: 100%;
  padding-left: 10px;
  background: #ffffff;
  border-radius: 0px;
  border: 1px solid #666666;
  height: 48px;
  color: rgb(51, 51, 51);
  font-family: "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.tag-container {
  color: #666666;
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  min-height: 35px;
}

.tag-container label {
  padding: 5px 10px;
  background: #e9eaeb;
  border-radius: 4px;
  margin-left: 5px;
}

.label-container {
  display: flex;
  justify-content: space-between;
}

.sentence-selector label {
  background: #d8d8d8;
  border-radius: 4px;
  padding: 3px 20px;
  margin-left: 10px;
  cursor: pointer;
}

.sentence-selector label.selected {
  background-color: #2a56dc;
  color: white;
}

.sentence-list-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;
}

.sentence-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(151, 151, 151);
  margin-bottom: 10px;
}

.sentence-list-header label {
  font-weight: bold;
}

/*=== UTILS ===*/
.width-fit-content {
  width: fit-content;
}

.float-right {
  float: right;
}

@media only screen and (max-width: 768px) {
  .meta-edit-button {
    float: none;
    width: 100%;
    margin-top: 2rem;
  }
}
.hide-element {
  display: none !important;
}

/**BOOK PREVIEW*/
/*! CSS Used from: https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css */
.mr-2 {
  margin-right: 0.5rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  img {
    page-break-inside: avoid;
  }
  h2,
  p {
    orphans: 3;
    widows: 3;
  }
  h2 {
    page-break-after: avoid;
  }
}
/* local font imports */
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../../../public/fonts/montserrat/montserrat-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"), url("../../../public/fonts/montserrat/montserrat-v14-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../../public/fonts/montserrat/montserrat-v14-latin-regular.woff2") format("woff2"), url("../../../public/fonts/montserrat/montserrat-v14-latin-regular.woff") format("woff"), url("../../../public/fonts/montserrat/montserrat-v14-latin-regular.ttf") format("truetype"), url("../../../public/fonts/montserrat/montserrat-v14-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}
.material-icons {
  font-family: Material Icons;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

.material-icons.md-large {
  font-size: var(--4pt);
}

.button {
  padding: 9px 20px;
  border-radius: 4px;
  color: #00414b;
  background-color: #f3f3f3;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border: none;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.button:hover {
  cursor: pointer;
}

.button__quinary {
  background-color: #e9eaeb;
}

@media (hover: hover) {
  .button__quinary:hover {
    background-color: #bdd5fd;
  }
}
.button__medium {
  padding: 7px 10px;
  font-size: 1rem;
}

.Text {
  font-family: "Montserrat", sans-serif;
  color: #00414b;
  font-weight: 400;
  line-height: 1.42rem;
  margin: 0;
}

.Text__body--color-petroleum--weight-semibold--transform-uppercase {
  font-weight: 600;
  text-transform: uppercase;
  color: #00414b;
}

.Title {
  font-family: "Montserrat", sans-serif;
  color: #00414b;
}

.Title__title6 {
  font-weight: 600;
  line-height: 1.57rem;
}

.Title__title6 {
  font-size: 1rem;
}

.Title__title6--weight-normal {
  font-weight: 400;
}

.book-cover {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 3px;
}

.book-cover img {
  width: 100%;
  max-height: 100%;
  height: auto;
}

.swiper-slide {
  height: 100%;
}

.swiper-slide::-webkit-scrollbar-track {
  background-color: #e9eaeb;
}

.swiper-slide::-webkit-scrollbar {
  width: 6px;
}

.swiper-slide::-webkit-scrollbar-thumb {
  background-color: #00414b;
}

.swiper-wrapper .work-card {
  margin: 0 var(--3pt) 0 0;
}

.diblio-logo {
  width: 17px;
  height: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.diblio-logo div {
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.diblio-logo div:first-child {
  width: 70%;
}

.diblio-logo div:last-child {
  width: 80%;
}

.RemindsOf {
  width: 143px;
  height: 33px;
  display: inline-flex;
  align-items: center;
  background-color: #74a9ff;
  border-radius: 4px;
  cursor: pointer;
}

.RemindsOf p {
  padding-top: 1px;
}

.RemindsOf .diblio-logo div {
  background-color: #00414b;
}

.work-card {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  margin-top: 50px;
  width: 215px;
  min-width: 215px;
  vertical-align: top;
  text-align: left;
  cursor: pointer;
}

.work-card label {
  font-weight: 500;
}

.work-card .work-card__content {
  height: 100%;
}

.work-card .book-cover {
  width: 100%;
  height: 70%;
  padding: 20px;
  background-color: #f8f8f8;
  object-fit: contain;
}

.work-card .book-cover:focus {
  outline: 2px solid;
}

.work-card .book-cover img {
  width: 100%;
  height: auto;
  pointer-events: none;
}

.work-card .work-card__title {
  margin: 0;
}

.work-card .work-card__creator {
  margin-bottom: var(--1pt);
}

.work-card .hover-details {
  text-align: left;
  opacity: 0;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.work-card:focus .hover-details,
.work-card:hover .hover-details {
  opacity: 1;
}

.hover-details-fade {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0)), color-stop(60%, #fff), color-stop(30%, #fff));
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 0, #fff 60%, #fff 0);
  z-index: 1;
}

.work-card:hover .hover-details-fade {
  opacity: 1;
}

.work-card .expand-more-wrapper {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: -180px;
  left: 0;
}

.work-card .work-card__tax-description {
  display: block;
  margin-top: var(--1pt) !important;
  line-height: 1.3;
}

@media only screen and (max-width: 768px) {
  .work-card {
    height: 350px;
    width: 160px;
    min-width: 160px;
  }
}
@media only screen and (max-width: 576px) {
  .work-card {
    height: 180px;
    width: 130px;
    min-width: 130px;
  }
  .work-card .book-cover {
    height: 100%;
    padding: 10px;
  }
  .hover-details,
  .hover-details-fade,
  .work-card .work-card__tax-description {
    display: none;
  }
}
button:disabled {
  color: #a7a7a7;
  background-color: #e9eaeb;
}

.diblio-logo {
  width: 17px;
  height: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.diblio-logo div {
  width: 100%;
  height: 3px;
  background-color: white;
}

.diblio-logo div:first-child {
  width: 70%;
}

.diblio-logo div:last-child {
  width: 80%;
}

.bookmark-icon {
  width: 22.4px !important;
}

.sentences-intro-text {
  margin-bottom: 20px;
}

.no-sentences-message {
  color: red;
  margin-top: 50px;
}

.no-sentences-table {
  margin-bottom: 70px;
}

.no-sentences-table .sentence-list-header {
  margin-bottom: 20px;
}

.thankyou {
  position: relative;
  padding: 50px;
  margin-top: 100px;
  max-width: 400px;
  margin-bottom: 50px;
  background-color: #f5f5f5;
  border: solid 1px lightgrey;
}

.thankyou--p {
  margin: 4rem;
}

.thankyou--team {
  display: inline-block;
  padding: 1rem;
  border-radius: 4px;
}

/*=== SPINNER ===*/
.spinner {
  display: inline-block;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
}

.spinner {
  margin-left: 5px;
  vertical-align: text-top;
  font-size: 18px;
  position: relative;
  text-indent: -9999em;
  border: 0.1em solid rgba(255, 255, 255, 0.2);
  border-left-color: #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.navigation--sidebar ul {
  list-style-type: none;
  padding-left: 0;
}
.navigation--sidebar li ul {
  height: 0;
  overflow: hidden;
  padding-left: 2rem;
  transition: all 200ms;
}
.navigation--sidebar ul.level-1 > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navigation--sidebar ul.level-1 > li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.navigation--sidebar li.is-openGroup > ul {
  display: block;
  height: auto;
  padding-bottom: 1rem;
}
.navigation--sidebar .navigation--title {
  display: inline-block;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.navigation--sidebar .navigation--title:first-letter {
  text-transform: capitalize;
}
.navigation--sidebar .navigation--title:hover {
  text-decoration: underline;
}
.navigation--sidebar .navigation--title .glyphicon {
  font-size: 12px;
  color: #ccc;
  position: absolute;
  right: 0rem;
}
.navigation--sidebar .level-1 .navigation--title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2rem;
  line-height: 1;
}
.navigation--sidebar .level-2 .navigation--title {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 1.5rem;
}
.navigation--sidebar .level-3 .navigation--title {
  font-size: 1.5rem;
}
.navigation--sidebar li.has-currentPage > .navigation--title {
  font-weight: 500;
}

.footer-container {
  display: table;
  background-color: #f2f2f2;
  padding: 0;
  padding-top: 10px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.footer {
  display: table-row;
}

.footer .footer-left {
  display: table-cell;
  height: 98px;
}

.footer .footer-left .vejl-btn {
  font-size: 12px;
}

.footer .footer-left .vejl-btn img {
  height: 12px;
  margin-right: 5px;
  margin-bottom: 4px;
}

.footer .footer-left .logo {
  position: absolute;
  bottom: 10px;
}

.footer .footer-left .logo img {
  height: 35px;
}

.footer .contact-info {
  font-size: 12px;
  text-align: right;
  display: table-cell;
}

.footer .contact-info h4 {
  margin: 0;
  margin-bottom: 5px;
}

.buggi-footer {
  background-color: #fff !important;
}

.accounting-app i {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.accounting-app .accounting-arrow-desc {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.accounting-app .accounting-arrow-asc {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.fade-appear {
  height: 0;
}

.fade-appear.fade-appear-active {
  height: 100%;
  transition: height 500ms ease-out;
}

@font-face {
  font-family: "Mukta-bold";
  src: url("../../../public/fonts/Mukta/mukta-bold-webfont.woff2") format("woff2"), url("../../../public/fonts/Mukta/mukta-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
html,
body {
  height: 100%;
}

label {
  font-weight: 500;
}

#root {
  height: 100%;
}
#root > div {
  height: 100%;
}

.b {
  font-weight: bold;
}


// Settings
@use "settings/colors";

// Utils
@use "utils/equalHeightColumns";
@use "utils/title";
@use "utils/spacing";
@use "utils/typography";
@use "utils/border";

// Components
@use "components/buggi/editor";
@use "components/buggi/emoji.scss";
@use "components/buggi/modal.scss";
@use "components/filter";
@use "components/login";
@use "components/material";
@use "components/meta";
@use "components/thankyou";
@use "components/spinner";
@use "components/navigation";
@use "components/footer";
@use "accounting/accounting";

// Transitions
@use "transitions/fade.transition";

@font-face {
  font-family: "Mukta-bold";
  src: url("../../../public/fonts/Mukta/mukta-bold-webfont.woff2") format("woff2"),
  url("../../../public/fonts/Mukta/mukta-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
}

label {
  font-weight: 500;
}

#root {
  height: 100%;

  > div {
    height: 100%;
  }
}

.b {
  font-weight: bold;
}
